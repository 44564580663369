import React from "react"
import imglogo from '../../../img/logo-removebg-preview (1).png'

const Head = () => {
  return (
    <>
      <section className='head'>
        <div className='container flexSB'>
          <div className='logo'>
            {/* <h1>Vimalagiri</h1>
            <span>International School</span> */}
            <img src={imglogo} alt="" />
          </div>

          <div className='social'>
            <i className='fab fa-facebook-f icon'></i>
            <i className='fab fa-instagram icon'></i>
            <i className="fa-brands fa-x-twitter"></i>
            {/* <i className='fab fa-youtube icon'></i> */}
          </div>
        </div>
      </section>
    </>
  )
}

export default Head
