import React, { useState, useEffect } from "react";
import Heading from "../../common/heading/Heading";
import "./Hero.css";
import axios from "axios";

const Hero = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://buzcatch.pythonanywhere.com/api/homepage/");
        setData(response.data.banner_sections[0]); // Assuming you want the first section
        console.log("Data fetched:", response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleDownload = async () => {
    console.log("Download button clicked");
    try {
      const response = await axios.get("https://buzcatch.pythonanywhere.com/api/download_application_form/", {
        responseType: "blob",
      });

      console.log("File downloaded:", response);
      // Create a Blob from the PDF Stream
      const blob = new Blob([response.data], { type: "application/pdf" });
      // Create a link element, use it to download the Blob, then remove it
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "application_form.pdf"); // Specify the file name
      document.body.appendChild(link);
      link.click();
      link.remove();
      // Revoke the object URL after download
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  if (!data) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <section className="hero">
        <div className="container">
          <div className="row">
            <Heading subtitle={data.content_one} title={data.content_two} />
            <p>{data.content_three}</p>
            <div className="button">
              <button className="primary-btn">
                GET STARTED NOW <i className="fa fa-long-arrow-alt-right"></i>
              </button>
              <button onClick={handleDownload}>
                APPLICATION FORM <i className="fa fa-long-arrow-alt-right"></i>
              </button>
            </div>
          </div>
        </div>
      </section>
      <div className="margin1"></div>
    </>
  );
};

export default Hero;
