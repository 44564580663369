import React from 'react';
import img1 from '../../img/a1.jpg';
import img2 from '../../img/a2.jpg';
import img3 from '../../img/a5.jpg';
import img4 from '../../img/a4.jpg';
import img5 from '../../img/a55.jpg';
import img6 from '../../img/img-6.jpg';
import img7 from '../../img/img-7.jpg';
import img8 from '../../img/img-8.jpg';
import img9 from '../../img/img-9.jpg';

const ImageGallery = () => {
    const containerStyle = {
        maxWidth: '85%',
        margin: '20px auto'
    };

    const imageContainerStyle = {
        columns: '3 250px',
        gap: '15px'
    };

    const imageStyle = {
        marginBottom: '10px',
        borderRadius: '5px',
        width: '100%'
    };

    return (
        <div style={containerStyle}>
            <div style={imageContainerStyle}>
                <img src={img1} alt="Image 1" style={imageStyle} />
                <img src={img2} alt="Image 2" style={imageStyle} />
                <img src={img3} alt="Image 3" style={imageStyle} />
                <img src={img4} alt="Image 4" style={imageStyle} />
                <img src={img5} alt="Image 5" style={imageStyle} />
                <img src={img6} alt="Image 6" style={imageStyle} />
                <img src={img7} alt="Image 7" style={imageStyle} />
                <img src={img8} alt="Image 8" style={imageStyle} />
                <img src={img9} alt="Image 9" style={imageStyle} />
            </div>
        </div>
    );
}

export default ImageGallery;
