import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './team.css'; // Ensure this path is correct

const TeamSection = () => {
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchTeams = async () => {
            try {
                const response = await axios.get("https://buzcatch.pythonanywhere.com/api/aboutus/");
                console.log(response.data); // Log the entire response to check its structure

                const teamData = response.data.administration_team_section || [];
                setTeams(teamData);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };

        fetchTeams();
    }, []);

    return (
        <div className="home-container">
            <h1 style={{ color: "white" }}>ADMINISTRATION TEAM</h1>
            <div className="home-container1">
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    teams.length > 0 ? (
                        teams.map((team) => (
                            <div className="profile-card" key={team.id}>
                                <div className="img">
                                    <img
                                        src={`https://buzcatch.pythonanywhere.com${team.image}`}
                                        alt={team.name}
                                        style={{ objectFit: 'cover', height: "190px", borderRadius: "50%", width: "100%" }}
                                    />
                                </div>
                                <div className="caption">
                                    <h3>{team.name}</h3>
                                    <p>{team.role}</p>
                                    {/* Uncomment and use FontAwesome if needed
                                <div className="social-links">
                                    {socialLinks.map((link, index) => (
                                        <a key={index} href={link.url} target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={link.icon} />
                                        </a>
                                    ))}
                                </div> */}
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No team data available</p>
                    )
                )}
            </div>
        </div>
    );
}

export default TeamSection;
