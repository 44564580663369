import React, { useContext } from "react";
import "./footer.css";
import { BlogContext } from "../../blog/BlogContext";
import { ContactContext } from "../../contact/ContactContext";
import Newsletter from "./Mail";


const Footer = () => {
  const { blogs, loading: blogsLoading, error: blogsError } = useContext(BlogContext);
  const { contacts, loading: contactsLoading, error: contactsError } = useContext(ContactContext);


  if (blogsLoading || contactsLoading) {
    return <div>Loading...</div>;
  }

  if (blogsError || contactsError) {
    return <div>Error: {blogsError || contactsError}</div>;
  }





  return (
    <>
      <section className='newletter'>
        <Newsletter />
      </section>
      <footer>
        <div className='container padding grid1 '>
          <div className='box logo'>
            <h1>VIMALAGIRI</h1>
            <span>International School</span>
            <p>Vimalagiri International School at Muvattupuzha is under the trust, namely Muvattupuzha Vimalagiri International Educational and Charitable Trust.</p>

            <i className='fab fa-facebook-f icon'></i>
            <i className='fab fa-instagram icon'></i>
          </div>
          <div className='box link'>
            <h3>Explore</h3>
            <ul>
              <li>About Us</li>
              <li>Vlog</li>
              <li>Gallery</li>
              <li>Blog</li>
              <li>Contact us</li>
            </ul>
          </div>
          <div className='box link' >
            <h3>Quick Links</h3>
            <ul>
              <li>Contact Us</li>
              <li>Terms & Conditions</li>
              <li>Privacy</li>
              <li>Feedbacks</li>
              <li>Home</li>

            </ul>
          </div>
          <div className='box'>
            <h3>Recent Post</h3>
            {blogs && blogs.slice(0, 3).map((val, index) => (
              <div className='items flexSB' style={{ justifyContent: "flex-start" }} key={index}>
                <div className='img'>
                  <img src={`https://buzcatch.pythonanywhere.com${val.blog_image}`} alt={val.blog_title} />
                </div>
                <div className='text'>
                  <span>
                    <i className='fa fa-calendar-alt'></i>
                    <label>{new Date(val.posted_date).toLocaleDateString()}</label>
                  </span>
                  <span>
                    <i className='fa fa-user'></i>
                    <label>{val.type || "Admin"}</label>
                  </span>
                  <h4>{val.blog_title.slice(0, 40)}...</h4>
                </div>
              </div>
            ))}
          </div>
          <div className='box last'>
            <h3>Have a Questions?</h3>
            <ul>
              {contacts && contacts.map((val, index) => (
                <li key={index}>
                  <i className='fa fa-map'></i> {val.address}
                </li>
              ))}
              {contacts && contacts.map((val, index) => (
                <li key={index}>
                  <i className='fa fa-phone-alt'></i> {val.phone_number_three}
                </li>
              ))}
              {contacts && contacts.map((val, index) => (
                <li key={index}>
                  <i className='fa fa-paper-plane'></i> {val.email}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </footer>
      <div className='legal'>
        <p>
          Copyright ©2024 All rights reserved | Design by &nbsp;
          <a href="https://www.buzcatch.com" target="_blank" color="grey">Buzcatch</a>
        </p>

      </div>
    </>
  );
};

export default Footer;
